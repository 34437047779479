import React, { useState, useEffect } from "react";
import styles from "./Admin.module.css";
import { db, collection, getDocs, updateDoc, doc } from "../../firebase-config"; 

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [editableUserId, setEditableUserId] = useState(null); 
  const [editedUserData, setEditedUserData] = useState({});
  const [showIncomplete, setShowIncomplete] = useState(false); 
  const [filterByField, setFilterByField] = useState({
    age: false,
    gender: false,
    schoolEmail: false,
    headshot: false,
    resume: false,
    location: false,
    ethnicity: false,
    school: false,
    major: false
  });
  const [passwordEntered, setPasswordEntered] = useState(false); 
  const [password, setPassword] = useState(""); 

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === "password") {  //right here
      setPasswordEntered(true);
    } else {
      alert("Incorrect password, try again.");
    }
  };

  useEffect(() => {
    if (passwordEntered) {
      const fetchUsers = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "Students"));
          const allUsers = [];
          querySnapshot.forEach((doc) => {
            allUsers.push({ uid: doc.id, ...doc.data() });
          });
          setUsers(allUsers);
        } catch (error) {
          console.error("Error fetching users: ", error);
        }
      };

      fetchUsers();
    }
  }, [passwordEntered]);

  const handleChange = (e, userId, field) => {
    const { value } = e.target;
    setEditedUserData((prev) => ({
      ...prev,
      [userId]: { ...prev[userId], [field]: value }
    }));
  };

  // Save changes permanently to Firebase
  const handleSave = async (userId) => {
    try {
      const userDocRef = doc(db, "Students", userId);
      const updatedData = editedUserData[userId];
      
      if (updatedData) {
        await updateDoc(userDocRef, updatedData);  // Save changes to Firebase
        
        // Update local state with the saved data
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user.uid === userId ? { ...user, ...updatedData } : user))
        );
        setEditableUserId(null); 
        alert("User data updated successfully!");
      } else {
        alert("No changes detected.");
      }
    } catch (error) {
      console.error("Error updating user data: ", error);
    }
  };

  const isUserMissingFields = (user) => {
    return !user.headshot || !user.resume || !user.location || !user.ethnicity || !user.school || !user.age || !user.gender || !user.schoolEmail || !user.fullName;
  };

  const isFieldEmpty = (user, field) => {
    return !user[field]; 
  };

  const filteredUsers = users.filter((user) => {
    if (showIncomplete && !isUserMissingFields(user)) return false;

    for (const [field, isFiltered] of Object.entries(filterByField)) {
      if (isFiltered && !isFieldEmpty(user, field)) return false;
    }

    return true; 
  });

  const handleFieldFilterToggle = (field) => {
    setFilterByField((prev) => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className={styles.adminContainer}>
      {!passwordEntered && (
        <div className={styles.passwordContainer}>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.passwordInput}
            />
            <button type="submit" className={styles.passwordSubmit}>Submit</button>
          </form>
        </div>
      )}

      {passwordEntered && (
        <div className={styles.adminInterface}>
          <div className={styles.toggleContainer}>
            <label>
              Show Only Incomplete Profiles
              <input
                type="checkbox"
                checked={showIncomplete}
                onChange={() => setShowIncomplete((prev) => !prev)}
                className={styles.toggleSwitch}
              />
            </label>
          </div>

          <div className={styles.tableWrapper}>
            <table className={styles.adminTable}>
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Full Name</th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.age ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("age")}
                    >
                      Age
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.gender ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("gender")}
                    >
                      Gender
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.schoolEmail ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("schoolEmail")}
                    >
                      School Email
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.headshot ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("headshot")}
                    >
                      Headshot
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.resume ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("resume")}
                    >
                      Resume
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.location ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("location")}
                    >
                      Location
                    </button>
                  </th>
                  <th>
                    <button
                      className={`${styles.headerButton} ${filterByField.ethnicity ? styles.activeFilter : ""}`}
                      onClick={() => handleFieldFilterToggle("ethnicity")}
                    >
                      Ethnicity
                    </button>
                  </th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.uid} className={isUserMissingFields(user) ? styles.missingFieldsRow : ""}>
                    <td>{user.uid}</td>
                    <td>{editableUserId === user.uid ? <input value={editedUserData[user.uid]?.fullName || user.fullName} onChange={(e) => handleChange(e, user.uid, "fullName")} className={styles.inputField} /> : user.fullName}</td>
                    <td>{editableUserId === user.uid ? <input type="number" value={editedUserData[user.uid]?.age || user.age} onChange={(e) => handleChange(e, user.uid, "age")} className={styles.inputField} /> : user.age}</td>
                    <td>{editableUserId === user.uid ? <input value={editedUserData[user.uid]?.gender || user.gender} onChange={(e) => handleChange(e, user.uid, "gender")} className={styles.inputField} /> : user.gender}</td>
                    <td>{editableUserId === user.uid ? <input value={editedUserData[user.uid]?.schoolEmail || user.schoolEmail} onChange={(e) => handleChange(e, user.uid, "schoolEmail")} className={styles.inputField} /> : user.schoolEmail}</td>
                    <td>{user.headshot ? <a href={user.headshot} target="_blank" rel="noopener noreferrer" className={styles.link}>View</a> : "No Headshot"}</td>
                    <td>{user.resume ? <a href={user.resume} target="_blank" rel="noopener noreferrer" className={styles.link}>View</a> : "No Resume"}</td>
                    <td>{editableUserId === user.uid ? <input value={editedUserData[user.uid]?.location || user.location} onChange={(e) => handleChange(e, user.uid, "location")} className={styles.inputField} /> : user.location}</td>
                    <td>{editableUserId === user.uid ? <input value={editedUserData[user.uid]?.ethnicity || user.ethnicity} onChange={(e) => handleChange(e, user.uid, "ethnicity")} className={styles.inputField} /> : user.ethnicity}</td>
                    <td>
                      {editableUserId === user.uid ? (
                        <button onClick={() => handleSave(user.uid)} className={styles.saveButton}>Save</button>
                      ) : (
                        <button onClick={() => setEditableUserId(user.uid)} className={styles.editButton}>Edit</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
