import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	auth,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	db,
	setDoc,
	doc,
} from "../../../firebase-config";
import styles from "./SignUpForm.module.css";

const SignUpForm = ({ toggleMode, setFirstTime }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const handleSignup = async (e) => {
		e.preventDefault();
		setLoading(true);

		// Check if the email ends with .edu
		if (email.endsWith(".edu")) {
			setError(
				"Work emails ending with .edu are not allowed. Please use a valid work email."
			);
			setLoading(false);
			return;
		}

		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;

			// Store only the full name in Firestore
			await setDoc(doc(db, "Startups", user.uid), {
				fullName: name,
			});

			await sendEmailVerification(user);

			setError(
				"Verification email sent! Please check your email to verify your account."
			);

			// Get the ID token to send it in the header
			const token = await user.getIdToken();

			// Call the backend route to generate the access code
			const response = await fetch(
				"/api/generate-access-code",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const result = await response.json();

			if (response.ok) {
				const accessCode = result.accessCode;

				// Store the access code in Firestore
				await setDoc(
					doc(db, "Startups", user.uid),
					{
						accessCode: accessCode, // Adding the generated access code
					},
					{ merge: true }
				);
			} else {
				console.error("Error generating access code:", result.message);
			}

			// Automatically switch to the Sign In form after sign-up
			toggleMode();
			setFirstTime(true);
		} catch (error) {
			setError("Error during signup. Please try again");
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className={styles.signUpForm} onSubmit={handleSignup}>
			<div className={styles.nameWrapper}>
				<input
					type="text"
					placeholder="Full Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.emailWrapper}>
				<input
					type="email"
					placeholder="Work Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.passwordWrapper}>
				<input
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			{error && <p className={styles.error}>{error}</p>}
			<div className={styles.submissionButton}>
				<button type="submit" className={styles.done} disabled={loading}>
					{loading ? (
						<span className={styles.spinner}></span>
					) : (
						<b className={styles.createProfile}>Sign Up</b>
					)}
				</button>
			</div>
		</form>
	);
};

SignUpForm.propTypes = {
	toggleMode: PropTypes.func.isRequired,
	setFirstTime: PropTypes.func.isRequired,
};

export default SignUpForm;
