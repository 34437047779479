import React, { useState, useEffect } from "react";
import Footer from "../../../common/Footer";
import { db, doc, getDoc } from "../../../firebase-config"; // Import Firestore methods
import { auth, onAuthStateChanged } from "../../../firebase-config"; // Import Firebase auth and onAuthStateChanged for user info
import styles from "./StartupsP3.module.css";
import ChatBot from "../../../common/ChatBot";
import ChatBotDemo from "../../../common/ChatBotDemo";

const StartupsP3 = () => {
	const [fullName, setFullName] = useState(""); // State to store the user's full name
	const [error, setError] = useState(null); // State to store potential errors
	const [hasAccess, setAccess] = useState(false);
	const [accessCode, setAccessCode] = useState("");
	const [codeActive, setCodeActive] = useState(false);
	const [demoActive, setDemoActive] = useState(false);
	const [careerSelected, setCareerSelected] = useState("");

	useEffect(() => {
		// Function to fetch user's fullName from Firestore
		const fetchUserInfo = async (user) => {
			try {
				const userDocRef = doc(db, "Startups", user.uid); // Reference to the user's document in Firestore
				const userDoc = await getDoc(userDocRef); // Fetch the document

				if (userDoc.exists()) {
					setFullName(userDoc.data().fullName); // Set fullName from Firestore data
					setCodeActive(userDoc.data().codeActive);
				} else {
					setFullName("User"); // Fallback if the document doesn't exist
				}
			} catch (error) {
				console.error("Error fetching user information:", error);
				setError("Failed to load user information."); // Set an error message
			}
		};

		// Listen for user state changes
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				fetchUserInfo(user); // Fetch user info if logged in
			} else {
				setFullName("Guest"); // Fallback for non-logged-in users
			}
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	const handleAccessCodeSubmit = async (e) => {
		e.preventDefault();
		try {
			const user = auth.currentUser;
			if (!user) {
				setError("Please log in to submit an access code.");
				return;
			}

			const userDocRef = doc(db, "Startups", user.uid); // Reference to the user's document
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists() && userDoc.data().accessCode === accessCode) {
				setAccess(true); // Set access to true if code is correct
			} else {
				setError("Invalid access code. Please try again.");
			}
		} catch (error) {
			console.error("Error validating access code:", error);
			setError("Failed to validate access code.");
		}
	};

	const handleDemoSubmit = () => {
		setDemoActive(!demoActive);
		setCareerSelected("");
		console.log("Demo mode: " + demoActive);
	};

	const handleCareerSelection = (career) => {
		setCareerSelected(career);
		console.log("Career Selected: " + career);
	};

	const renderHeader = () => {
		return (
			<h1 className={styles.startRecruiting}>
				{error ? error : `Welcome, ${fullName}`}
			</h1>
		);
	};

	const renderDemoNavigation = () => {
		return (
			<div className={styles.demoNavigation}>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Accounting")}>
					Accounting
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Business")}>
					Business
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Engineering")}>
					Engineering
				</button>
				<button className={styles.careerButton} onClick={() => handleCareerSelection("Nursing")}>
					Nursing
				</button>
			</div>
		);
	};

	const renderAccessForm = () => {
		return (
			<div className={styles.accessContainer}>
				<form
					onSubmit={handleAccessCodeSubmit}
					className={styles.accessCodeForm}
				>
					<label className={styles.formLabel} htmlFor="accessCode">
						Enter Access Code:
					</label>
					<input
						type="text"
						className={styles.accessCode}
						value={accessCode}
						placeholder="access code"
						onChange={(e) => setAccessCode(e.target.value)}
						required
					/>
					<button className={styles.formButton} type="submit">
						Enter
					</button>
				</form>
				<h1 className={styles.formLabel}>or</h1>
				<div className={styles.buttonDiv}>
					<label className={styles.demoLabel}>
						Try a demo with sample students
					</label>
					<button className={styles.demoButton} onClick={handleDemoSubmit}>
						View Demo
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className={styles.p3Wrapper}>
			<div className={styles.p3Header}>{renderHeader()}</div>
			<h1 className={styles.studentsHeader}>
				Meet the students on our platform
			</h1>

			{hasAccess && codeActive && !demoActive ? (
				<div className={styles.chatBotContainer}>
					<ChatBot />
				</div>
			) : demoActive ? (
				<div className={styles.demoContainer}>
					{renderDemoNavigation()}
					{/* Only display ChatBotDemo and Exit Demo button if a career is selected */}
					{careerSelected && careerSelected !== "" && (
						<>
							<ChatBotDemo careerPath={careerSelected} />
							<button className={styles.exitDemoButton} onClick={handleDemoSubmit}>Exit Demo</button>
						</>
					)}
				</div>
			) : (
				renderAccessForm()
			)}

			<Footer />
		</div>
	);
};

export default StartupsP3;
