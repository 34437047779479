import React, { useState, useRef } from "react";
import Footer from "../../../common/Footer"; // Adjust the path as needed
import styles from "./AboutP2.module.css";
import { useNavigate } from "react-router-dom";

const AboutP2 = () => {
  const [isFaster, setIsFaster] = useState(false);
  const [isCheaper, setIsCheaper] = useState(false);
  const [isEasier, setIsEasier] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsFaster(!isFaster);
  }
  
  const popupRef = useRef(null);
  const handleButtonSnap = () => {
    setIsFaster(!isFaster);
    if (popupRef.current) {
      popupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Cheaper Popup
  const handleCheaperButtonSnap = () => {
    setIsCheaper(!isCheaper);
    if (popupRef.current) {
      popupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const toggleCheaperPopup = () => {
    setIsCheaper(!isCheaper);
  }

  // Easier Popup
  const handleEasierButtonSnap = () => {
    setIsEasier(!isEasier);
    if (popupRef.current) {
      popupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const toggleEasierPopup = () => {
    setIsEasier(!isEasier);
  }
  return (
    <div className={styles.aboutP2}>
      <div className={styles.aboutP2Child} />
      <main className={styles.aboutP2Inner}>
        <section className={styles.vectorParent}>
          <img
            className={styles.vectorIcon}
            loading="lazy"
            alt=""
            src="/vector1.svg"
          />
          <div className={styles.frameParent}>
            <div className={styles.frameContainer}>
              <div className={styles.frameGroup}>
                <div className={styles.connectingStudentsWithStartWrapper}>
                  <h1 className={styles.connectingStudentsWith}>
                    connecting students with startups
                  </h1>
                </div>
                <div className={styles.description}>
                  <h1 className={styles.anAiPowered}>
                    {`an AI-powered platform for networking and recruiting`}
                  </h1>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.ctaParent}>
                    <button className={styles.cta} onClick={() => navigate('/about-p1')}>
                      <h3 className={styles.networking}>Networking</h3>
                    </button>
                    <button className={styles.cta1}>
                      <b className={styles.recruiting}>Recruiting</b>
                    </button>
                  </div>
                </div>
                <div className={styles.fceWrapper}>
                  <div className={styles.fce}>
                    <div className={styles.faster} onClick={togglePopup}>
                      <div className={styles.fasterrec} />
                        <button className={styles.popupButton} onClick={handleButtonSnap}>
                          <img
                            className={styles.phlightningBoldIcon}
                            loading="lazy"
                            alt="Lighting Bolt Icon"
                            src="/images/bolt.svg"
                          />
                        </button>
                      <div className={styles.fasterWrapper}>
                          <h1 className={styles.faster1}>
                            <span>FASTER</span>
                          </h1>
                      </div>
                        {isFaster && (
                          <div className={styles.popupWrapper} ref={popupRef}>
                            <div className={styles.popup} >
                                <p>Employers can fill positions faster</p>
                            </div>
                          </div>
                        )}
                    </div>
                    {/* Cheaper Section */}
                    <div className={styles.cheaper} onClick={toggleCheaperPopup}>
                      <div className={styles.cheaperrec} />
                        <button className={styles.cheaperPopupButton} onClick={handleCheaperButtonSnap}>
                          <div className={styles.streamlinebagDollarWrapper}>
                            <img
                              className={styles.streamlinebagDollarIcon}
                              loading="lazy"
                              alt="Dollar Sign Icon"
                              src="/images/bag.svg"
                            />
                          </div>
                        </button>
                        <h1 className={styles.cheaper1}>
                          <span>CHEAPER</span>
                        </h1>
                          {isCheaper && (
                            <div className={styles.cheaperPopupWrapper} ref={popupRef}>
                              <div className={styles.cheaperPopup} >
                                  <p>Employers can try for free</p>
                              </div>
                            </div>
                          )}
                      </div>
                    {/* Easier Section */}
                    <div className={styles.easier} onClick={toggleEasierPopup}>
                      <div className={styles.easierrec} />
                        <button className={styles.easierPopupButton} onClick={handleEasierButtonSnap}>
                          <img
                            className={styles.akarIconsgear}
                            loading="lazy"
                            alt="Gear Icon"
                            src="/images/gear.svg"
                          />
                        </button>
                        <div className={styles.easierWrapper}>
                            <h1 className={styles.easier1}>
                              <span>EASIER</span>
                            </h1>
                        </div>
                          {isEasier && (
                            <div className={styles.easierPopupWrapper} ref={popupRef}>
                              <div className={styles.easierPopup} >
                                  <p>Employers can hire qualified students easier</p>
                              </div>
                            </div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className={styles.p2Footer}>
        <Footer />
      </div>
    </div>
  );
};

export default AboutP2;
